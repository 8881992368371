<!-- Navigation -->
<nav id="mainNav" class="navbar navbar-default navbar-fixed-top navbar-custom">
    <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header page-scroll">
            <div>
                <a class="logo-font" href="#page-top">speckles</a>
            </div>
            <search-box></search-box>
        </div>
    </div><!-- /.container-fluid -->
</nav>