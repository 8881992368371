import {Special} from '../shared/models/special.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';


@Injectable()
export class ZipcodeSearchService {
    private specials: Special[];
    private specialsSubject = new BehaviorSubject(this.specials);
    specialsObservable = this.specialsSubject.asObservable();
    public setReceivedSpecials(specials: Special[]) {
        this.specialsSubject.next(specials);
    }
}
