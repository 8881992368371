import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Special} from './shared/models/special.model';
import {environment} from '../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'TimeZone': 'America/New_York'})
};


@Injectable()
export class AppService {
    constructor(private http: HttpClient) {
    }

    getSpecials(page: number, latitude: number, longitude: number) {
        const searchArea = {'myLatitude': latitude, 'myLongitude': longitude, 'myRadius': 20};
        httpOptions.headers.set('TimeZone', 'America/New_York');
        return this.http.post<Special[]>(environment.apiUrl + '/v1/specials/vicinity/ALL/NONE/' + page, searchArea,
            httpOptions);
    }
}
