import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppService} from './app.service';
import {SearchService} from './search/search.service';

import {AppComponent} from './app.component';
import {SearchComponent} from './search/search.component';
import {AppHeaderComponent} from './header/app-header.component';
import {ZipcodeSearchService} from './service/zipcodesearch.service';
import {LoginComponent} from './login/login.component';
import {LoginService} from './login/login.service';
import {AppRoutingModule} from './app-routing.module';
import {AdminComponent} from './admin/admin.component';
import {SpecialsService} from './specials/specials.service';
import {SpecialsComponent} from './specials/specials.component';
import {FormsModule} from '@angular/forms';
import {AdminNavbarComponent} from './admin/navbar/admin-navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTabGroup, MatTabsModule} from '@angular/material/tabs';

@NgModule({
    declarations: [
        AppComponent, AppHeaderComponent, SearchComponent, LoginComponent, AdminComponent, SpecialsComponent,
        AdminNavbarComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule, MatTabsModule
    ],
    providers: [AppService, SearchService, ZipcodeSearchService, LoginService, SpecialsService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
