<app-admin-navbar></app-admin-navbar>
<div class="mt40">
    <div id="verify-dialog" title="Verify users">
        <p class="validateTips">Are you sure you want to verify this user(s)?</p>
    </div>

    <div class="panel with-nav-tabs panel-default">
        <!-- Default panel contents -->

        <div class="panel-heading">
            <ul class="nav nav-tabs below-nav-bar">
                <li class="active"><a href="#verifyTab" data-toggle="tab">Verify</a></li>
                <li><a href="#usersTab" data-toggle="tab">Users</a></li>
                <li><a href="#venuesTab" data-toggle="tab">Venues</a></li>
            </ul>
        </div>
        <!--hack to access apiUrl in js file-->
        <input id="apiUrl" type="hidden" value="{{apiUrl}}"/>
        <div class="tab-content">
            <div class="tab-pane fade in active" id="verifyTab">
                <!-- Table -->
                <table id="accounts" class="display select" cellspacing="0" width="100%">
                    <thead>
                    <div>
                        <input type="submit" name="verify" id="verify" tabindex="4" class="form-control verify-button" value="Verify">
                    </div>
                    <tr>
                        <th>Venue</th>
                        <th>Email</th>
                        <th>Created</th>
                    </tr>
                    </thead>
                    <tfoot>
                    <tr>
                        <th>Venue</th>
                        <th>Email</th>
                        <th>Created</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div class="tab-pane fade" id="usersTab">
                <!-- Table -->
                <table id="users" class="display select" cellspacing="0" width="100%">
                    <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Last Updated</th>
                    </tr>
                    </thead>
                    <tfoot>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Last Updated</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div class="tab-pane fade" id="venuesTab">
                <!-- Table -->
                <table id="venues" class="display select" cellspacing="0" width="100%">
                    <thead>
                    <tr>
                        <th>Business Name</th>
                        <th>Email</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip Code</th>
                        <th>Date Joined</th>
                    </tr>
                    </thead>
                    <tfoot>
                    <tr>
                        <th>Business Name</th>
                        <th>Email</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip Code</th>
                        <th>Date Joined</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>

    </div>
</div>