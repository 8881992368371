import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SearchService} from './search.service';
import {Zipcode} from '../shared/models/zipcode.model';
import {AppService} from '../app.service';
import {Special} from '../shared/models/special.model';
import {ZipcodeSearchService} from '../service/zipcodesearch.service';

@Component({
    selector: 'search-box',
    templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit{
    zipCodeInfo: Zipcode;
    specials: Special[];

    constructor(private searchService: SearchService, private appService: AppService, private zipcodesearchService: ZipcodeSearchService) {
    }

    fetchZipCodeInfo(zipcode: string) {
        const self = this;
        self.searchService.getZipcodeInfo(zipcode).subscribe(data => {
            self.zipCodeInfo = data;
            console.log(self.zipCodeInfo);
            const zipCoords = self.zipCodeInfo.coordinates;
            localStorage.setItem('coordinates', JSON.stringify(zipCoords));
            self.appService.getSpecials(0, zipCoords.y, zipCoords.x).subscribe(responseData => {
                console.log(localStorage.getItem('coordinates'));
                self.zipcodesearchService.setReceivedSpecials(responseData);
            });
        });
    }

    ngOnInit(): void {
        this.zipcodesearchService.specialsObservable.subscribe(specials => this.specials = specials);
    }
}
