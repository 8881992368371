import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Zipcode } from '../shared/models/zipcode.model';
import {environment} from '../../environments/environment';

@Injectable()
export class SearchService {
    constructor(private httpClient: HttpClient) {
    }
    getZipcodeInfo(zipcode: string) {
        return this.httpClient.get<Zipcode>(environment.apiUrl + '/v1/zipcodes/' + zipcode);
    }
}
